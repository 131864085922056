import Axios from "axios";

const state = {
	availableCompanies: [],
	selectedCompany: [],
	selectedCompanyImage: null,
	oneCompany: null,

	companyContacts: [],
	companyChatTypes: [],
	mapsCenterPoint: { lat: 51.772007, lng: 9.372456 },
};

const getters = {
	companyContacts: (state) => state.companyContacts,
	companyChatTypes: (state) => state.companyChatTypes,
	oneCompany: (state) => state.oneCompany,
	availableCompanies: (state) => state.availableCompanies,
	selectedCompany: (state) => state.selectedCompany,
	selectedCompanyImage: (state) => state.selectedCompanyImage,
	mapsCenterPoint: (state) => state.mapsCenterPoint,
};
const mutations = {
	setCompanyContacts: (state, value) => {
		state.companyContacts = value;
	},
	setCompanyChatTypes: (state, value) => {
		state.companyChatTypes = value;
	},
	setAvailableCompanies: (state, value) => {
		state.availableCompanies = value;
	},
	setSelectedCompany: (state, value) => {
		state.selectedCompany = value;
	},
	setOneCompany: (state, value) => {
		state.oneCompany = value;
	},
	setOneCompanyImages: (state, value) => {
		state.oneCompany.images = value;
	},
	setSelectedCompanyImage: (state, value) => {
		state.selectedCompanyImage = value;
	},
	setMapsCenterPoint: (state, value) => {
		state.mapsCenterPoint = value;
	},
};
const actions = {
	getAvailableCompanies({ commit }, { occupationId, offerId, filterText, exhibitionFilter }) {
		Axios.get(window.baselink + "getAvailableCompanies", {
			params: {
				occupationId,
				offerId,
				filterText,
				exhibitionFilter
			},
		}).then(function(response) {
			if (response.status == 200) {
				commit("setAvailableCompanies", response.data.companies);
			}
		});
	},
	async getOneCompany({ commit }, { id }) {
		commit("setOneCompany", null);
		await Axios.get(window.baselink + "getCompany/" + id).then(function(
			response
		) {
			if (response.status == 200) {
				commit("setOneCompany", response.data.company);
			}
		});
		if (state.oneCompany && state.oneCompany.images.length > 0) {
			commit("setSelectedCompanyImage", state.oneCompany.images[0]);
		}
	},
};

export default {
	namespace: "companies",
	state,
	getters,
	actions,
	mutations,
};
