var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.oneCompany && _vm.selectedCompanyImage)?_c('div',{staticClass:"h100 w-100"},[(
			_vm.oneCompany.images.length == 1 &&
				((_vm.selectedCompanyImage.fileType == 'video' &&
					_vm.youtube_parser(_vm.selectedCompanyImage.link)) ||
					_vm.selectedCompanyImage.fileType != 'video' ||
					(_vm.selectedCompanyImage.fileType == 'video' &&
						_vm.is_vimeo(_vm.selectedCompanyImage.link)))
		)?_c('div',{staticClass:"h100"},[_c('div',{staticClass:"image__box image__box--l"},[(
					_vm.selectedCompanyImage.fileType == 'video' &&
						!_vm.is_vimeo(_vm.selectedCompanyImage.link) &&
						_vm.youtube_parser(_vm.selectedCompanyImage.link)
				)?_c('youtube',{staticStyle:{"max-height":"40vh"},attrs:{"video-id":_vm.youtube_parser(_vm.selectedCompanyImage.link)}}):_vm._e(),(
					_vm.selectedCompanyImage.fileType == 'video' &&
						_vm.is_vimeo(_vm.selectedCompanyImage.link)
				)?_c('vimeo-player',{staticStyle:{"max-height":"40vh"},attrs:{"video-id":_vm.get_vimeo_id(_vm.selectedCompanyImage.link)}}):_vm._e(),(_vm.selectedCompanyImage.fileType != 'video')?_c('img',{staticStyle:{"max-height":"40vh"},attrs:{"src":_vm.selectedCompanyImage.link}}):_vm._e()],1)]):_vm._e(),(_vm.oneCompany.images.length > 1)?_c('div',{staticClass:"row__start row__start--stretch h100 image__scrollBox"},[_c('div',{staticClass:"row__start--flex1 column__start scrollbar",staticStyle:{"max-width":"25%","height":"10vh"}},_vm._l((_vm.oneCompany.images),function(image){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
					(image.fileType == 'video' &&
						_vm.youtube_parser(image.link)) ||
						image.fileType != 'video' ||
						(image.fileType == 'video' && _vm.is_vimeo(image.link))
				),expression:"\n\t\t\t\t\t(image.fileType == 'video' &&\n\t\t\t\t\t\tyoutube_parser(image.link)) ||\n\t\t\t\t\t\timage.fileType != 'video' ||\n\t\t\t\t\t\t(image.fileType == 'video' && is_vimeo(image.link))\n\t\t\t\t"}],key:image.id,staticClass:"image__container pointer",class:image.id == _vm.selectedCompanyImage.id
						? 'image__container--active'
						: '',on:{"click":function($event){$event.stopPropagation();return _vm.changeCompanyImage(image)}}},[(
						image.fileType == 'video' &&
							_vm.youtube_parser(image.link) &&
							!_vm.is_vimeo(image.link)
					)?_c('youtube',{attrs:{"video-id":_vm.youtube_parser(image.link)}}):_vm._e(),(image.fileType == 'video' && _vm.is_vimeo(image.link))?_c('vimeo-player',{staticStyle:{"height":"100px"},attrs:{"video-id":_vm.get_vimeo_id(image.link)}}):_vm._e(),(image.fileType == 'video')?_c('div',{staticClass:"absolute--overlay",on:{"click":function($event){$event.stopPropagation();return _vm.changeCompanyImage(image)}}}):_vm._e(),(image.fileType != 'video')?_c('img',{attrs:{"src":image.link}}):_vm._e()],1)}),0),_c('div',{staticClass:"row__start--flex3"},[_c('div',{staticClass:"image__box image__box--l",class:_vm.selectedCompanyImage.fileType == 'video' &&
					!_vm.is_vimeo(_vm.selectedCompanyImage.link) &&
					_vm.youtube_parser(_vm.selectedCompanyImage.link)
						? ''
						: 'image__box--hide'},[_c('youtube',{attrs:{"video-id":_vm.youtube_parser(_vm.selectedCompanyImage.link)}})],1),_c('div',{staticClass:"image__box image__box--l",class:_vm.selectedCompanyImage.fileType == 'video' &&
					_vm.is_vimeo(_vm.selectedCompanyImage.link)
						? ''
						: 'image__box--hide'},[(
						_vm.selectedCompanyImage.fileType == 'video' &&
							_vm.is_vimeo(_vm.selectedCompanyImage.link)
					)?_c('vimeo-player',{staticStyle:{"height":"100%"},attrs:{"video-id":_vm.get_vimeo_id(_vm.selectedCompanyImage.link)}}):_vm._e()],1),_c('div',{staticClass:"image__box image__box--l",class:_vm.selectedCompanyImage.fileType != 'video'
						? ''
						: 'image__box--hide'},[(_vm.selectedCompanyImage.fileType != 'video')?_c('img',{attrs:{"src":_vm.selectedCompanyImage.link}}):_vm._e()])])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }