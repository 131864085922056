<template>
    <div class="appointmentbox">
        <h3 class="m-0 text__primary mh-10">
            {{
                appointment.date +
                    ", " +
                    appointment.start_time +
                    " - " +
                    appointment.end_time +
                    " Uhr"
            }}
        </h3>
        <p>{{ appointment.company.name }}</p>
        <a
            class="color__black"
            v-if="appointment.contact.phone"
            :href="'tel:' + appointment.contact.phone"
        >
            {{ "Tel.Nr.: " + appointment.contact.phone }}
        </a>
        <a
            class="color__black"
            v-else-if="appointment.company.phone"
            :href="'tel:' + appointment.company.phone"
        >
            {{ "Tel.Nr.: " + appointment.company.phone }}
        </a>
        <p v-else-if="appointment.contact.email">
            {{ "E-mail.: " + appointment.contact.email }}
        </p>
        <p>{{ "Termin ID: " + appointment.termin_id }}</p>
        <div class="row__spacebetween">
            <p>{{ "Art: " + appointment.chat_type }}</p>
            <div class="row_end">
                <p
                    v-if="appointment.chat_type == 'Telefon'"
                    class="text__grey pointer text__hover--primary mr-25"
                    @click.stop="openAppointmentModal('info')"
                >
                    Info
                </p>
                <p
                    v-else-if="appointment.chat_type == 'Veranstaltung'"
                    class="text__grey pointer text__hover--primary mr-25"
                    @click.stop="openAppointmentModal('infoFair')"
                >
                    Info
                </p>
                <p
                    v-else
                    class="text__grey pointer text__hover--primary mr-25"
                    @click="startAppointment"
                >
                    Starten
                </p>
                <p
                    class="text__grey pointer text__hover--primary"
                    @click.stop="openAppointmentModal('delete')"
                >
                    Stornieren
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "appointment",
    props: ["appointment"],
    data() {
        return {};
    },
    methods: {
        startAppointment() {
            if (this.appointment.chat_type == "Chat") {
                // this.$router.push({
                //     name: "Chat",
                //     params: { id: this.appointment.id },
                // });
                window.open(window.JitsiURL + this.appointment.code, "_blank");
            }
            if (this.appointment.chat_type == "Video") {
                window.open(window.JitsiURL + this.appointment.code, "_blank");
            }
        },
        openAppointmentModal(modalType) {
            this.$emit("openModal", this.appointment, modalType);
        },
    },
    created() {},
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
};
</script>
