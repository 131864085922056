<template>
    <div class="navbar row__spacebetween main__padding">
        <div class="row__spacebetween w-100">
            <a class="logo--svg" href="https://www.step1-hx.de/">
                <LogoStepNavbar />
            </a>
            <div class="row__spacebetween navbar--row m-15 for__navbar--big">
                <a
                    class="navbar--link"
                    v-for="route in navbarRoutes"
                    :key="route.id"
                    :href="route.link"
                    >{{ route.name }}</a
                >
            </div>
            <a
                href="https://step1-hx.de/kontakt/"
                class="contact-button row__center for__navbar--big"
            >
                <p class="text__white small">KONTAKT</p>
            </a>
            <div
                class="for__navbar--small pointer"
                @click="navbarActive = !navbarActive"
            >
                <BurgerMenu :active="navbarActive" />
            </div>
        </div>
        <div
            class="navbar__dropdown"
            :class="{ 'navbar__dropdown--active': navbarActive }"
        >
            <a
                v-for="route in navbarRoutes"
                :key="route.id"
                :href="route.link"
                >{{ route.name }}</a
            >
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "Navbar",
    data() {
        return {
            navbarActive: false,
            navbarRoutes: [
                {
                    id: 1,
                    name: "Startseite",
                    link: "https://step1-hx.de/",
                },
                {
                    id: 2,
                    name: "für Schüler / Schülerinnen",
                    link: "https://step1-hx.de/schuelerinnen/",
                },
                {
                    id: 3,
                    name: "für Lehrkräfte",
                    link: "https://step1-hx.de/lehrerinnen/",
                },
                {
                    id: 4,
                    name: "für Eltern",
                    link: "https://step1-hx.de/eltern/",
                },
                {
                    id: 5,
                    name: "für Aussteller",
                    link: "https://step1-hx.de/aussteller/",
                },
                {
                    id: 6,
                    name: "Über uns",
                    link: "https://step1-hx.de/ueber-uns/",
                },
            ],
        };
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {},
    mounted() {},
};
</script>
