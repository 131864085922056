import Axios from "axios";
import router from "../../router";

const state = {
  token: null,
};

const getters = {
  token: (state) => state.token,
};
const mutations = {
  setToken: (state, token) => {
    state.token = token;
  },
};
const actions = {
  changePassword({ dispatch }, { oldPassword, newPassword, confirmPassword }) {
    return Axios.put(
      window.baselink + "candidate",
      {
        oldPassword,
        newPassword,
        confirmPassword,
        column: "password",
      },
    )
      .then(function (response) {
        if (response.status == 200) {
          return true;
        }
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          window.localStorage.removeItem("token");
          dispatch("setToken", null);
          router.push({
            name: "Login",
          });
        } else if (error.response.status == 404) {
          return error.response.data;
        }
      });
  },
  loginUser({ commit, dispatch }, { email, password }) {
    return Axios.post(window.baselink + "candidate/login", {
      email,
      password,
    })
      .then(function (response) {
        if (response.status == 200) {
          dispatch("setToken", response.data.token);
          window.localStorage.setItem("token", state.token);
          return true;
        }
      })
      .catch(function (error) {
        if (error.response.status == 401) {
          // return error.response.data.message;
          return "Das hat leider nicht funktioniert. Versichern Sie sich, dass Sie die richtige E-Mail-Adresse und das richtige Passwort verwenden.";
        }
        if (error.response.status == 403) {
          return "Das hat leider nicht funktioniert. Bitte bestätigen Sie Ihre E-Mail Adresse, prüfen Sie dazu auch den SPAM-Ordner.";
        }
        commit("setToken", null);
      })
  },
  setToken({ commit, state }, token = null) {
    if (token === null && window.localStorage.getItem("token") !== null) {
      commit("setToken", window.localStorage.getItem("token"));
    } else {
      commit("setToken", token);
    }
    Axios.defaults.headers = {
      Authorization: "Bearer " + state.token,
      Accept: "application/json",
    };
  },
  registerUser({ commit }, { mail, password, confirmPassword }) {
    return Axios.post(window.baselink + "candidate", {
      mail,
      password,
      confirmPassword,
    })
      .then(function (response) {
        if (response.status == 200) {
          return true;
        }
      })
      .catch(function (error) {
        commit("setToken", null);

        if (error.response.status == 401) {
          localStorage.removeItem("token");
          return error.response.data.text;
        }
      });
  },
  async logoutUser({ dispatch }) {
    await Axios.post(window.baselink + "candidate/logout")
      .then(() => {
        window.localStorage.removeItem("token");
        dispatch("setToken", null);
        return router.push("/login");
      })
      .catch(() => {
        window.localStorage.removeItem("token");
        dispatch("setToken", null);
        return router.push("/login");
      });
  },
  checkLogin({ commit }) {
    const token = window.localStorage.getItem("token");

    if (!(token != null)) {
      commit("setToken", null);
    }
  },
};

export default {
  namespace: "auth",
  state,
  getters,
  actions,
  mutations,
};
