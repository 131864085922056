<template>
    <div class="navbar navbar--top row__spacebetween main__padding">
        <a href="https://www.step1-hx.de/" v-if="showNavbar"> <StepOneSvg /></a>
        <div class="navbar--routes row__start" v-if="showNavbar">
            <div
                class="navbar--route"
                v-for="link in links"
                :key="link.id"
                :class="token ? 'mr-25' : ''"
            >
                <router-link
                    :to="{ name: link.name }"
                    v-if="link.id == 1 || token"
                >
                    <h3 class="text__grey">{{ link.label }}</h3>
                </router-link>
            </div>
        </div>
        <div
            v-if="token"
            class="responsive_left pointer"
            @click="showUserOptions = !showUserOptions"
            v-click-outside="hideUserOptions"
        >
            <HumanSvg />
            <NavbarUserOptions v-if="showUserOptions" />
        </div>
        <router-link :to="{ name: 'Login' }" class="button" v-if="showNavbar">
            <p class="text__white">Anmelden</p>
        </router-link>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
export default {
    name: "Navbar",
    data() {
        return {
            // !TODO
            showNavbar: false, //button hat sonst v-else
            links: [
                { id: 1, name: "Home", label: "Suche" },
                { id: 2, name: "Calendar", label: "Termine" },
                { id: 3, name: "Settings", label: "Einstellungen" },
            ],
            showUserOptions: false,
        };
    },
    methods: {
        ...mapActions([]),
        hideUserOptions() {
            this.showUserOptions = false;
        },
    },
    created() {},
    computed: {
        ...mapGetters(["token"]),
    },
    mounted() {},
    directives: {
        ClickOutside,
    },
};
</script>
