import Axios from "axios";

const state = {
  offerOptions: [],
  occupationOptions: [],
  fair: null,
};

const getters = {
  offerOptions: (state) => state.offerOptions,
  occupationOptions: (state) => state.occupationOptions,
  fair: (state) => state.fair,
};
const mutations = {
  setOfferOptions: (state, bool) => {
    state.offerOptions = bool;
  },
  setOccupationOptions: (state, bool) => {
    state.occupationOptions = bool;
  },
  setFair: (state, bool) => {
    state.fair = bool;
  },
};
const actions = {
  getDashboardOptions({ commit }) {
    Axios.get(window.baselink + "getDashboardOptions").then(function (response) {
      if (response.status == 200) {
        commit("setOfferOptions", response.data.offers);
        commit("setOccupationOptions", response.data.occupations);
        commit("setFair", response.data.fair);
      }
    });
  },
};

export default {
  namespace: "options",
  state,
  getters,
  actions,
  mutations,
};
