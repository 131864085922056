<template>
    <div
        class="checkBox__select row__start"
        @click="makeClickEvent"
        :class="classes"
        :style="customstyle"
    >
        <div
            class="checkBox__select--box"
            :class="active ? 'checkBox__box--active' : ''"
        >
            <i class="fas fa-check fa-xs" data-check="radio-button"></i>
        </div>
        <p>{{ text }}</p>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    name: "checkBox",
    props: ["classes", "active", "text", "id", "customstyle"],
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
        makeClickEvent() {
            this.$emit("click", this.id);
        },
    },
    created() {},
    computed: {
        ...mapGetters([]),
    },
    mounted() {},
};
</script>
